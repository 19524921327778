<template>
  <div class="statement-detail">
    <app-header :title="title" :isShowBack="true"></app-header>
    <app-overview title="// 提款信息 //" :list="actualList"></app-overview>
    <app-overview title="// 转账信息 //" :list="actual"></app-overview>
  </div>
</template>
<script>
export default {
  data() {
    let _this = this;
    return {
      title: "转账详情",
      actualList: [
        {
          label: "提款流水号",
          key: "withdrawId",
          value: ""
        },
        {
          label: "提款人",
          key: "withdrawAdult",
          value: ""
        },
        {
          label: "提款时间",
          key: "withdrawTime",
          value: ""
        },
        {
          label: "提款金额",
          key: "withdrawAmount",
          value: ""
        },
        {
          label: "到帐情况",
          key: "arrivalState",
          value: ""
        },
        {
          label: "到账时间",
          key: "arrivalTime",
          value: ""
        },
        {
          label: "提款说明",
          key: "withdrawRemark",
          value:
            "入驻威富智慧公寓平台后，从平台提取房客缴纳房租，水电费，押金等费用，不扣取任何服务费用，提款申请提交成功后，正常在1-3个工作日后到账。"
        },
        {
          label: "",
          key: "",
          value: ""
        }
      ],
      actual: [
        {
          label: "转账状态",
          key: "transferState",
          value: ""
        },
        {
          label: "转账金额",
          key: "withdrawAmount",
          value: ""
        },
        {
          label: "凭证",
          key: "transferPath",
          type: "img-list",
          on(data) {
            _this.$alert(
              `<img src="${_this.imgUrl}" style="width:100%">`,
              "查看凭证",
              {
                dangerouslyUseHTMLString: true,
                callback: action => {}
              }
            );
          }
        },
        {
          label: "转账说明",
          key: "transferRemark",
          value: ""
        }
      ],
      tit: "",
      opt: {
        title: "账单明细(" + _this.cache.get("statementDetailTitle", "") + ")",
        isShowBack: true,
        search: [
          {
            key: "reconState",
            label: "对账状态",
            type: "select",
            opt: {
              list: [
                {
                  label: "正常",
                  value: 1
                },
                {
                  label: "异常",
                  value: 2
                },
                {
                  label: "已冲正",
                  value: 3
                }
              ]
            }
          }
        ]
      },
      imgUrl: ""
    };
  },
  activated() {
    this.onGet();
  },
  methods: {
    onGet(opt) {
      let dto = {
        withdrawId: this.$route.query.withdrawId
      };
      this.post("/finance-service/withdraw/transferRecordDetail", dto, {
        isUseResponse: true
      }).then(res => {
        var data = res.data.data;
        this.imgUrl = data.transferPath;
        data.transferPath = "查看";
        var keys = [];
        for (var key in data) {
          keys.push(key);
        }
        this.actualList.forEach(item => {
          item.value = data[item.key];
          if (item.key == "arrivalState") {
            item.value = data["arrivalState"] == "1" ? "到账" : "未到账";
          }
          if (item.key == "withdrawRemark") {
            item.value =
              "入驻威富智慧公寓平台后，从平台提取房客缴纳房租，水电费，押金等费用，不扣取任何服务费用，提款申请提交成功后，正常在1-3个工作日后到账。";
          }
        });
        this.actual.forEach(item => {
          item.value = data[item.key];
          if (item.key == "transferState") {
            item.value = data["transferState"] == "1" ? "已转账" : "未转账";
          }
        });
      });
    }
  }
};
</script>

<style lang="scss" scope>
.el-message-box {
  width: 780px;
}
.statement-detail .el-col-12 {
  height: 90px !important;
}
</style>
